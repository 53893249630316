<template>
    <div class="container-fluid p-0">
        <div v-if="dataReady" id="singlePodcastDiv" class="row">
            <div id="podcast-info" class="col-lg-6 col-md-12">
                <div class="single-podcast-header">
                    <div class="single-podcast-header-text">
                        <h2><strong>{{this.podcastName}}</strong></h2>
                        <h4>{{this.artist}}</h4>
                    </div>
                    <div class="image-and-save-div">
                        <div id="single-podcast-header-image">
                            <b-img
                                   rounded
                                   class="single-podcast-image"
                                   :src="require(`@/assets/img/${this.podcastId}.jpg`)"
                                   alt="">
                            </b-img>
                        </div>
                    </div>
                    <!-- <div id="main-genres" clas="row">
                        <small><u>{{genreString}}</u></small>
                    </div> -->
                    <div v-if="this.$store.state.isAuthenticated" class="save-wishlisten-buttons mt-3 d-flex">
                        <div>
                            <button id="save-podcast-button" v-if="!this.alreadySaved" @click.prevent="savePodcast">
                                Add to favorites
                            </button>
                            <button id="already-saved-podcast-button" v-if="this.alreadySaved" @click.prevent="savePodcast">
                                Remove from favorites
                            </button>
                        </div>
                        <div>
                            <button id="save-podcast-button" v-if="!this.alreadyWishlistened" @click.prevent="addPodcastToWishListens">
                                Add to wishlistens
                            </button>
                            <button id="already-saved-podcast-button" v-if="this.alreadyWishlistened" @click.prevent="addPodcastToWishListens">
                                Remove from wishlistens
                            </button>
                        </div>
                    </div>

                    <div v-else class="mt-3">
                            <button id="podcast-login-button" @click="$parent.$parent.showLogin =! $parent.$parent.showLogin">
                                Login to save podcasts
                            </button>
                    </div>
                </div>
                <div id="summary-div" v-if="summary">
                    <p class="summary"
                       v-if="showFullSummary"
                       @click="showFullSummary = !showFullSummary" title="Click to show less">
                        {{summary}}
                   </p>
                    <p class="summary"
                       v-else @click="showFullSummary = !showFullSummary" title="Click to show more">
                        {{shortSummary}}...
                   </p>
                </div>
                <div class="genre-scores-container d-none d-lg-block">
                    <div class="genre-scores-div">
                        <apexchart
                                v-if="dataReady"
                                width="380"
                                height="auto"
                                type="pie"
                                :options="chartOptions"
                                :series="this.scores">
                        </apexchart>
                    </div>
                </div>
                <div class="genre-scores-container mobile d-s-block d-lg-none">
                    <div class="genre-scores-div">
                        <apexchart
                                v-if="dataReady"
                                width="260"
                                height="auto"
                                type="pie"
                                :options="chartOptions"
                                :series="this.scores">
                        </apexchart>
                    </div>
                </div>
            </div>
            <div v-if="this.related" id="similar-podcasts-container" class="col-xl-6 col-lg-12">
                <div v-if="this.related.results.length > 0" id="similar-podcasts-on-page">
                    <SearchResults
                        :header="`Podcasts like ${this.podcastName}`"
                        :searchResultsData="this.related">
                    </SearchResults>
                </div>
                <div v-else>
                    <p>Could not find any podcasts like <strong>{{this.podcastName}}</strong></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SearchResults from '@/components/search_results/SearchResults.vue'
export default {
    watch: {
        title(newVal) {
            this.title = newVal
        }
    },
    name: 'Podcast',
    components: {
        SearchResults
    },
    data() {
        return {
            podcast: null,
            router: this.$route.params.podcastid,
            podcastName: '',
            artist: '',
            podcastId: '',
            trackCount: 0,
            summary: '',
            genres: [],
            artworkUrl: '',
            country: '',
            releaseDate: null,
            horrorScore: 0,
            thrillerScore: 0,
            mysteryScore: 0,
            sciFiScore: 0,
            fantasyScore: 0,
            crimeScore: 0,
            comedyScore: 0,
            folkloreScore: 0,
            related: {results: []},
            relevantScores: [],
            showFullSummary: false,
            shortSummary: '',
            visualizedScores: [],
            totalRelevantScore: 0,
            rssFeed: null,
            // genreString: '',
            labels: [],
            scores: [],
            userSavedPodcasts: null,
            userWishlistens: null,
            dataReady: false,
            chartOptions: {
                theme: {
                    monochrome: {
                        enabled: true,
                        color: '#3c4b80',
                    }
                },
                legend: {
                    position: 'left',
                    fontSize: '10em',
                    labels: {
                        colors: ['#ffffff'],
                    },
                },
                dataLabels: {
                    position: 'top'
                },
                chart: {
                    type: 'pie',
                },
                labels: [],
                responsive: [{
                    breakpoint: 360,
                    options: {
                        chart: {
                            width: '50%'
                        },
                    }
                }]
            }
        }
    },
    mounted() {
        this.getPodcast()
        this.getUserSavedPodcasts()
    },
    computed: {
        alreadySaved() {
            if (this.userSavedPodcasts) {
                return this.userSavedPodcasts.find(x => x.podcast_id === this.podcastId) !== undefined
            }
            else {
                return false
            }
        },
        alreadyWishlistened() {
            if (this.userWishlistens) {
                return this.userWishlistens.find(x => x.podcast_id === this.podcastId) !== undefined
            } else {
                return false
            }
        }
    },
    methods: {
        getPodcast() {
            this.$http.get(`${this.$store.state.endpoints.baseUrl}podcasts/${this.router}/`).then((response) => {
                let pod = response.data
                this.podcast = response.data
                this.podcastName = pod.name
                this.artist = pod.artist
                this.podcastId = pod.podcast_id
                this.trackCount = pod.track_count
                this.summary = pod.summary.replace(/(<([^>]+)>)/gi, "");
                // this.genres = pod.genres
                // if (typeof this.genres === 'string') {
                //     this.genres = [this.genres]
                // }
                this.artworkUrl = pod.artwork_url
                this.country = pod.country
                this.releaseDate = pod.release_date.slice(0,10)
                this.horrorScore = pod.horror_score
                this.thrillerScore = pod.thriller_score
                this.mysteryScore = pod.mystery_score
                this.sciFiScore = pod.sci_fi_score
                this.fantasyScore = pod.fantasy_score
                this.crimeScore = pod.crime_score
                this.comedyScore = pod.comedy_score
                this.folkloreScore = pod.folklore_score
                this.shortSummary = this.summary.slice(0, 200)
                this.rssFeed = pod.feed_url
                this.related.results = pod.related_podcasts
                document.title = `${this.$store.state.title} - ${this.podcastName}`
                this.dataReady = true
                this.calculateScores()
                // this.concatGenreString()
            })
        },
        getUserSavedPodcasts() {
            if (this.$store.state.isAuthenticated) {
                let axiosConfig = {
                    headers: {
                        Authorization: `JWT ${this.$store.state.jwt}`,
                        'Content-Type': 'application/json'
                    }
                }
                let userUrl = `${this.$store.state.endpoints.baseUrl}users/${this.$store.state.username}/`
                this.$http.get(userUrl, axiosConfig).then((response) => {
                    this.userSavedPodcasts = response.data.saved_podcasts
                    this.userWishlistens = response.data.wishlisten_podcasts
                })
            }
        },
        calculateScores() {
            let relevantScores = {}
            let allScores = {'Horror': this.horrorScore, 'Thriller': this.thrillerScore,
                             'Mystery': this.mysteryScore, 'Sci-Fi': this.sciFiScore,
                             'Fantasy': this.fantasyScore, 'Crime': this.crimeScore,
                             'Comedy': this.comedyScore, 'Folklore': this.folkloreScore}


            for (const [key, value] of Object.entries(allScores)) {
                if (value > 10) {
                    this.totalRelevantScore += value
                    relevantScores[key] = value
                }
            }
            var sorted = {}
            Object.keys(relevantScores).sort((a, b) => relevantScores[b] - relevantScores[a]).forEach((key) => {
                sorted[key] = relevantScores[key]
            })
            this.relevantScores = sorted
            this.labels = Array.from(Object.keys(this.relevantScores))
            this.scores = Array.from(Object.values(this.relevantScores))
            this.chartOptions = {...this.chartOptions, ...{
                labels: this.labels
            }}
        },
        // concatGenreString() {
        //     let tempGenreString = ''
        //     for (let genre of this.genres) {
        //         tempGenreString += genre.toLowerCase() + ', '
        //     }
        //     this.genreString = tempGenreString.slice(0, -2)
        // },
        savePodcast() {
            let saved_podcast = {
                "saved_podcasts": [{"podcast_id": this.podcastId}]
            }
            let axiosConfig = {
                headers: {
                    Authorization: `JWT ${this.$store.state.jwt}`,
                    'Content-Type': 'application/json'
                }
            }
            this.$http.patch(`${this.$store.state.endpoints.baseUrl}users/${this.$store.state.username}/`,
                saved_podcast, axiosConfig).then(() => {
                    this.getUserSavedPodcasts()
            })
        },
        addPodcastToWishListens() {
            let wishlistenPodcast = {
                "wishlisten_podcasts": [{"podcast_id": this.podcastId}]
            }
            let axiosConfig = {
                headers: {
                    Authorization: `JWT ${this.$store.state.jwt}`,
                    'Content-Type': 'application/json'
                }
            }
            this.$http.patch(`${this.$store.state.endpoints.baseUrl}users/${this.$store.state.username}/`,
                wishlistenPodcast, axiosConfig).then(() => {
                    this.getUserSavedPodcasts()
            })
        }
    }
}
</script>

<style scoped>
    #singlePodcastDiv {
        /* background: var(--darkestBlue); */
        padding: 20px;
        border-radius: 20px;
        color: white;
    }
    .single-podcast-image {
        height: 25vh;
        width: 25vh;
    }
    .single-podcast-header {
        display: flex;
        flex-direction: column;
    }
    .single-podcast-header-text {
        display: flex;
        flex-direction: column;
    }
    #main-genres {
        display: flex;
        flex-direction: row;
        gap: 4px;
        margin-bottom: 1em;
    }
    .summary {
        cursor: pointer;
    }
    .genre-scores-container {
        /* justify-content: center; */
        margin-top: 2em;
        margin-right: 5em;
        display: flex;
        margin-left: auto;
        max-height: 50%;
    }
    .genre-scores-container.mobile {
        margin: 0px;
        display: flex;
    }
    .genre-scores-div {
    }
    .single-genre-score {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .genre-label {
        border-radius: 10px;
        width: 80%;
        color: white;
        /* display: flex; */
        text-align: center;
        justify-content: center;
        align-content: center;
        align-items: center;
        flex-grow: 1;
    }
    .genre-label p {
        font-weight: bolder;
        color: white;
        box-shadow: var(--globalButtonShadow);
    }
    .genre-score {
        margin: 0px;
    }
    #similar-podcasts-container {
    }
    #similar-podcasts-on-page {
        height: 80vh;
        overflow-y: scroll;
        display: inline-block;
        padding: 20px;
        scrollbar-width: none;
    }
    #similar-podcasts-on-page::-webkit-scrollbar {
        display: none;
    }
    .image-and-save-div {
        display: flex;
    }
    #save-podcast-button {
        padding: 0.4em;
        margin-bottom: 1em;
        border-radius: 1em;
        box-shadow: var(--globalButtonShadow);
        border: none;
        background: #6998ff;
        color: white;
        width: 150px;
        height: 60px;
        margin-right: 0.2em;
    }
    #save-podcast-button:active {
        transform: translateY(4px);
        background: var(--hoverColor);
    }
    #save-podcast-button:hover {
        -webkit-transition-duration: 0.2s;
        transition-duration: 0.2s;
    }
    #already-saved-podcast-button {
        padding: 0.4em;
        margin-bottom: 1em;
        border-radius: 1em;
        box-shadow: 3px 3px 3px #7a3535,
            -1px -1px 3px #be5555;
        border: none;
        background: #be5555;
        color: white;
        width: 150px;
        height: 60px;
        margin-right: 0.2em;
    }
    #already-saved-podcast-button:active {
        transform: translateY(4px);
        -webkit-transition-duration: 0.2s;
        transition-duration: 0.2s;
        background: #be4b4b;
    }
    #podcast-login-button {
        padding: 1em;
        margin-bottom: 1em;
        border-radius: 1em;
        box-shadow: var(--globalButtonShadow);
        border: none;
        background: white;
        color: white;
        color: var(--darkBackground);
        font-weight: bold;
    }
</style>
