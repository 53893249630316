<template lang="html">
  <div class="component-wrapper">
    <Podcast></Podcast>
  </div>
</template>

<script>
import Podcast from '@/components/podcast/Podcast.vue'

export default {
    name: 'PodcastView',
    components: {
        Podcast
    }

}
</script>

<style lang="css">
</style>
